import { useEffect, useRef, useState } from "react";
import styles from "./EndTimer.module.scss";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import DateDiff from "date-diff";
import { format } from "date-fns";
import { Stack, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { MobileScreenWidth } from "../../../constants";

interface RemainingTime {
    hours: number;
    minutes: number;
    seconds: number;
}

const padTime = (timePart: number) => {
    const timePartStr = timePart.toString();
    return timePartStr.length === 1 ? `0${timePartStr}` : timePartStr;
};

interface EndTimerProps {
    endDate: Date | undefined;
    endDateDescription: string | undefined;
    isLarge: boolean;
    onCountdownFinished?: () => void;
    withoutTime?: boolean;
}

const EndTimer = ({
    endDate,
    endDateDescription,
    isLarge,
    onCountdownFinished,
    withoutTime,
}: EndTimerProps) => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });
    const [showDateTime, setShowDateTime] = useState(false);
    const [showDaysRemaining, setShowDaysRemaining] = useState(false);
    const [showTimer, setShowTimer] = useState(false);
    const [remainingTime, setRemainingTime] = useState<RemainingTime>({
        hours: 0,
        minutes: 0,
        seconds: 0,
    });
    const [endDateString, setEndDateString] = useState("");
    const [daysRemainingString, setDaysRemainingString] = useState("");
    const intervalIdRef = useRef<NodeJS.Timer>();

    useEffect(() => {
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
        }

        if (endDate) {
            const today = new Date();
            const diff = new DateDiff(endDate, today);
            const daysRemaining = Math.floor(diff.days());
            const formatInfo = withoutTime ? "dd-MMM-yyyy" : "dd-MMM-yyyy h:mm a";
            setEndDateString(format(endDate, formatInfo));
            setDaysRemainingString(`${daysRemaining} ${daysRemaining === 1 ? 'day' : 'days'}`);

            if (diff.days() > 14) {
                setShowDateTime(true);
                setShowDaysRemaining(false);
                setShowTimer(false);
            } else if (diff.days() > 2) {
                setShowDateTime(isLarge);
                setShowDaysRemaining(true);
                setShowTimer(false);
            } else {
                setShowDateTime(isLarge);
                setShowDaysRemaining(false);
                setShowTimer(true);
                const totalSecs = diff.seconds();
                const totalMins = Math.trunc(totalSecs / 60);
                const remainingSecs = Math.trunc(totalSecs - totalMins * 60);
                const remainingHours = Math.trunc(totalMins / 60);
                const remainingMins = totalMins - remainingHours * 60;

                setRemainingTime({
                    hours: remainingHours > 0 ? remainingHours : 0,
                    minutes: remainingMins > 0 ? remainingMins : 0,
                    seconds: remainingSecs > 0 ? remainingSecs : 0,
                });

                if (
                    remainingHours > 0 ||
                    remainingMins > 0 ||
                    remainingSecs > 0
                ) {
                    intervalIdRef.current = setInterval(() => {
                        setRemainingTime((prev) => {
                            const newValue = { ...prev };

                            if (newValue.seconds > 0) {
                                newValue.seconds = newValue.seconds - 1;
                            } else if (newValue.minutes > 0) {
                                newValue.seconds = 59;
                                newValue.minutes = newValue.minutes - 1;
                            } else if (newValue.hours > 0) {
                                newValue.seconds = 59;
                                newValue.minutes = 59;
                                newValue.hours = newValue.hours - 1;
                            }

                            if (
                                newValue.hours === 0 &&
                                newValue.minutes === 0 &&
                                newValue.seconds === 0
                            ) {
                                if (intervalIdRef.current) {
                                    clearInterval(intervalIdRef.current);
                                }

                                if (onCountdownFinished) {
                                    setTimeout(() => {
                                        onCountdownFinished();
                                    }, 1500);
                                }
                            }

                            return newValue;
                        });
                    }, 1000);
                }
            }
        } else if (endDateDescription) {
            setShowTimer(false);
            setShowDaysRemaining(false);
            setDaysRemainingString('');
            setEndDateString(endDateDescription);
        } else {
            setShowTimer(false);
            setShowDaysRemaining(false);
            setDaysRemainingString('');
            setEndDateString("");
        }
    }, [endDate, endDateDescription, isLarge, onCountdownFinished, withoutTime]);

    return (
        <Stack direction='column' className={isDesktop ? styles.root : styles.rootMobile}>
            {showDateTime ? (
                <Stack direction="row" className={isLarge ? styles.endDateContainerLarge : styles.endDateContainer}>
                    <Typography className={styles.auctionEndValue} noWrap>
                        {endDateString}
                    </Typography>
                </Stack>
            ) : null}
            {showDaysRemaining ? (
                <Stack direction="row" className={styles.endDaysContainer}>
                    <Typography className={styles.endDaysValue} noWrap>
                        {daysRemainingString}
                    </Typography>
                </Stack>
            ) : null}
            {showTimer ? (
                <Stack direction="row" className={styles.endTimeContainer}>
                    <TimerOutlinedIcon className={styles.timerIcon} />
                    <Typography className={styles.auctionEndValue} noWrap>{`${padTime(
                        remainingTime.hours,
                    )}:${padTime(remainingTime.minutes)}:${padTime(
                        remainingTime.seconds,
                    )}`}</Typography>
                </Stack>
            ) : null}
        </Stack>
    );
};

export default EndTimer;
