import styles from './ContactInfo.module.scss';
import AuctionSellImage1 from '../../../assets/auctionSellImage1.jpg';
import AuctionSellImage1Mobile from '../../../assets/auctionSellImage1Mobile.jpg';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from "react-responsive";
import { MobileScreenWidth } from '../../../../constants';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import {ReactComponent as InstagramIcon} from '../../../assets/instagram.svg';
import {ReactComponent as TwitterIcon} from '../../../assets/x-twitter.svg';
import CopyrightFooter from '../../footer/CopyrightFooter';
import { Helmet } from 'react-helmet-async';

const ContactInfo = () => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });

    return (
        <>
        <Helmet>
            <title>Watch Shuffle - Contact Us</title>
            <meta name="description" content="Contact us. Email: hello@watchshuffle.com. Twitter: https://twitter.com/watchshuffle. Telephone: 03003023135. Instagram: https://www.instagram.com/watchshuffle/" />
            <link rel="canonical" href="/contact" />
        </Helmet>

        <div className={styles.root}>
            <div className={styles.contentContainer}>
                <div className={styles.imageContainer1}>
                    <img className={styles.auctionSellImage} src={isDesktop ? AuctionSellImage1 : AuctionSellImage1Mobile} alt="Contact Us" />
                </div>

                <div className={isDesktop ? styles.titleContainer : styles.titleContainerMobile}>
                    <Typography className={styles.titleText}>Contact Us</Typography>
                </div>

                <div className={styles.iconsContainer}>
                    <div className={styles.contactRowContainer}>
                        <a href="mailto:hello@watchshuffle.com"><EmailOutlinedIcon className={styles.emailIcon}/></a>
                        <div className={styles.contactTextContainer}>
                            <Typography className={styles.contactText}>Email: <a href="mailto:hello@watchshuffle.com">hello@watchshuffle.com</a></Typography>
                        </div>
                        
                        <a href="https://twitter.com/watchshuffle" target="_blank" rel="noreferrer noopener"><TwitterIcon className={styles.twitterIcon}/></a>
                        <div className={styles.contactTextContainer}>
                            <Typography className={styles.contactText}>Twitter: <a href="https://twitter.com/watchshuffle" target="_blank" rel="noreferrer noopener">@WatchShuffle</a></Typography>
                        </div>
                        
                        <a href="tel:03003023135"><LocalPhoneIcon className={styles.phoneIcon}/></a>
                        <div className={styles.contactTextContainer}>
                            <Typography className={styles.contactText}>Tel: <a href="tel:03003023135">0300 302 3135</a></Typography>
                        </div>

                        <a href="https://www.instagram.com/watchshuffle/" target="_blank" rel="noreferrer noopener"><InstagramIcon className={styles.instagramIcon}/></a>
                        <div className={styles.contactTextContainer}>
                            <Typography className={styles.contactText}>Instagram: <a href="https://www.instagram.com/watchshuffle/" target="_blank" rel="noreferrer noopener">@watchshuffle</a></Typography>
                        </div>
                    </div>
                </div>
            </div>
            
            <CopyrightFooter />
        </div>
        </>
    );
}

export default ContactInfo;