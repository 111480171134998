import styles from "./AuctionBuy.module.scss";
import AuctionBuyImage1 from "../../../../assets/auctionBuyImage1.png";
import AuctionBuyImage1Mobile from "../../../../assets/auctionBuyImage1Mobile.png";
import Footer from "../../../footer/Footer";
import {
    ChangeEvent,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { StatusFilter } from "./StatusFilter";
import {
    InputAdornment,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";
import { WatchInfoBuyDetails } from "../../../../models/WatchInfoBuyDetails";
import {
    getWatchStatusCounts,
    getWatchesByStatus,
    watchUnwatch,
} from "../../../../services/auction-service";
import AuctionItem from "./AuctionItem";
import SearchIcon from "@mui/icons-material/Search";
import { PageSelector } from './PageSelector';
import { useMediaQuery } from "react-responsive";
import { MobileScreenWidth } from "../../../../../constants";
import AuctionItemMobile from "./AuctionItemMobile";
import { loggingService } from "../../../../../utils/logging/logging";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
    setSearchText,
    setSortBy,
    setStatusFilter,
} from "../../../../store/showroomSlice";
import Connector from "../../../../services/signalr-service";
import { SnackContext } from "../../../../providers/SnackProvider";
import { AuthContext } from "../../../../providers/AuthProvider";
import { Helmet } from "react-helmet-async";

const { log } = loggingService("app:AuctionBuy");

const initialStatusToCount = {
    Live: 0,
    ComingSoon: 0,
    EndedSold: 0,
};

interface AuctionBuyProps {
    fromRoot: boolean
}

const AuctionBuy = ({fromRoot} : AuctionBuyProps) => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });
    const isDesktopRef = useRef(isDesktop);
    isDesktopRef.current = isDesktop;

    const { user, login } = useContext(AuthContext);
    const dispatch = useAppDispatch();
    const showSnackbar = useContext(SnackContext);
    const { statusFilter, sellerType, sortBy, searchText } = useAppSelector(
        (store) => store.showroom,
    );
    const statusFilterRef = useRef(statusFilter);
    statusFilterRef.current = statusFilter;
    const [statusToCount, setStatusToCount] =
        useState<Record<string, number>>(initialStatusToCount);
    const [auctionItems, setAuctionItems] = useState<WatchInfoBuyDetails[]>([]);
    const auctionItemsRef = useRef(auctionItems);
    auctionItemsRef.current = auctionItems;
    const [auctionItemsChuncks, setAuctionItemsChuncks] = useState<
        WatchInfoBuyDetails[][]
    >([]);
    const [displayedAuctionItems, setDisplayedAuctionItems] = useState<
        WatchInfoBuyDetails[]
    >([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [numPagesToDisplay, setNumPagesToDisplay] = useState(1);
    // const [itemsPerRow, setItemsPerRow] = useState(4);
    const [itemsPerPage, setItemsPerPage] = useState(8);
    const { events } = Connector();

    const chunk = (items: WatchInfoBuyDetails[], size: number) =>{
        return Array.from({ length: Math.ceil(items.length / size) }, (v, i) =>
            items.slice(i * size, i * size + size)
        );
    }

    const loadStatusCounts = useCallback(async (status: string) => {
        try {
            const counts = await getWatchStatusCounts();
            setStatusToCount((prev) => {
                return {
                    Live: status === "Live" ? prev.Live : counts.Live,
                    ComingSoon:
                        status === "ComingSoon"
                            ? prev.ComingSoon
                            : counts.ComingSoon,
                    EndedSold:
                        status === "EndedSold"
                            ? prev.EndedSold
                            : counts.EndedSold,
                };
            });
        } catch (error) {
            log("Error retrieving watch listing status counts", error);
            setStatusToCount(initialStatusToCount);
        }
    }, []);

    const loadWatches = useCallback(
        async (status: string) => {
            try {
                log("getting watch summary");
                const items = await getWatchesByStatus(status);
                log("getting watch summary result", items);
                setAuctionItems(items);
                setStatusToCount((prev) => {
                    const newVal = { ...prev };
                    newVal[status] = items.length;
                    return newVal;
                });
            } catch (error) {
                log("Error retrieving watch listings", error);
                showSnackbar(
                    "An error occurred loading the watch listings. Please try again later.",
                    { alertSeverity: "error" },
                );
                setAuctionItems([]);
                setStatusToCount(initialStatusToCount);
            }
        },
        [showSnackbar],
    );

    useEffect(() => {
        loadStatusCounts(statusFilter);
    }, [loadStatusCounts, statusFilter]);

    useEffect(() => {
        loadWatches(statusFilter);
    }, [loadWatches, statusFilter]);

    const updateItemsPerPage = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth >= 1600) {
            setItemsPerPage(16);
        } else if (screenWidth >= 768) {
            setItemsPerPage(12);
        } else {
            setItemsPerPage(8);
        }
    };

    useEffect(() => {
        updateItemsPerPage();
        window.addEventListener("resize", updateItemsPerPage);

        return () => {
            window.removeEventListener("resize", updateItemsPerPage);
        };
    }, []);

    useEffect(() => {
        const searchTextLowerCase = searchText.toLowerCase();
        const display = searchTextLowerCase
            ? [
                  ...auctionItems.filter(
                      (item) =>
                          (!sellerType || item.sellerType === sellerType) &&
                          (!searchTextLowerCase ||
                              item.brand
                                  .toLowerCase()
                                  .includes(searchTextLowerCase) ||
                              item.model
                                  .toLowerCase()
                                  .includes(searchTextLowerCase) ||
                              item.year
                                  .toLowerCase()
                                  .includes(searchTextLowerCase)),
                  ),
              ]
            : [...auctionItems];

        switch (sortBy) {
            case "brand":
                display.sort((a, b) => a.brand.localeCompare(b.brand));
                break;
            case "model":
                display.sort((a, b) =>
                    a.model
                        .replace('"', "")
                        .localeCompare(b.model.replace('"', "")),
                );
                break;
            case "endingSoon":
                display.sort((a, b) => {
                    const value1 = a.auctionEndDate?.getTime() ?? 0;
                    const value2 = b.auctionEndDate?.getTime() ?? 0;
                    return value1 === value2 ? 0 : value1 < value2 ? -1 : 1;
                });
                break;
            case "currentBid":
                display.sort((a, b) =>
                    a.currentBid === b.currentBid
                        ? 0
                        : a.currentBid < b.currentBid
                        ? -1
                        : 1,
                );
                break;
        }

        if (!display || display.length === 0) {
            setNumPagesToDisplay(0);
            setAuctionItemsChuncks([]);
            setDisplayedAuctionItems([]);
            setCurrentPage(1);
            return;
        }

        const auctionChunks = chunk(display, itemsPerPage);
        setNumPagesToDisplay(auctionChunks.length);
        setAuctionItemsChuncks(auctionChunks);

        if (currentPage > auctionChunks.length) {
            setCurrentPage(1);
        } else {
            setDisplayedAuctionItems(auctionChunks[currentPage - 1] || []);
        }
    }, [auctionItems, searchText, sellerType, sortBy, currentPage, itemsPerPage]);

    useEffect(() => {
        events((notification) => {
            log("Received listing update notification", notification);
            if (
                (notification.status <= 4 && statusFilter === "ComingSoon") ||
                (notification.status === 5 && statusFilter === "Live") ||
                (notification.status >= 6 && statusFilter === "EndedSold")
            ) {
                log(
                    `Listing status matches current status filter.  Refreshing`,
                );
                loadWatches(statusFilterRef.current);
                return;
            }

            const foundWatch = auctionItemsRef.current.find(
                (item) => item.id === notification.id,
            );

            if (foundWatch) {
                log(
                    `Listing update received for watch ${foundWatch.brand} ${foundWatch.brand}.  Refreshing`,
                );
                loadWatches(statusFilterRef.current);
            } else {
                log(
                    `Listing update does not match currently displayed item in status filter ${statusFilter}. notification.Id=${notification.id}`,
                );
            }
        });
    }, [events, loadWatches, statusFilter]);

    const updateItems = useCallback(
        (id: string, value: boolean) => {
            setAuctionItems((prev: any) => {
                return prev.map((item: any) =>
                    item.id === id ? { ...item, isFavourite: value } : item,
                );
            });
        },
        [setAuctionItems],
    );

    const onStatusFilterChanged = useCallback(
        (value: string) => {
            if(!value) dispatch(setStatusFilter(statusFilter));
            else dispatch(setStatusFilter(value));
        },
        [dispatch, statusFilter],
    );

    const onSortByChange = useCallback(
        (event: SelectChangeEvent) => {
            dispatch(setSortBy(event.target.value));
        },
        [dispatch],
    );

    const onPageChanged = useCallback((value: number) => {
        setCurrentPage(value);
    }, []);

    useEffect(() => {
        if(auctionItemsChuncks.length) setDisplayedAuctionItems(auctionItemsChuncks[currentPage - 1])
    }, [currentPage, auctionItemsChuncks])

    const sortByClasses = {
        standard: styles.sortByDropdown,
        select: styles.sortByDropdownSelected,
    };

    const onSearchChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            dispatch(setSearchText(event.target.value));
        },
        [dispatch],
    );

    const onCountdownFinished = useCallback(() => {
        log("Auction end time reached.  Refreshing");
        loadWatches(statusFilterRef.current);
    }, [loadWatches]);

    const onFavouritesToggle = useCallback(
        async (info: WatchInfoBuyDetails) => {
            try {
                if (!user) {
                    return showSnackbar(
                        `To continue enjoying all the features, please login to your account.`,
                        {
                            alertSeverity: "warning",
                            actions: [
                                {
                                    label: "Login",
                                    onClick: () => login(!isDesktopRef.current),
                                },
                            ],
                        },
                    );
                }

                await watchUnwatch(info.id, {
                    watching: !info.isFavourite,
                });
                updateItems(info.id, !info.isFavourite);
            } catch (error: any) {
                showSnackbar(error.message, { alertSeverity: "error" });
            }
        },
        [showSnackbar, updateItems, user, login],
    );

    const isTenantAdmin = useMemo(() => user?.role.includes('Tenant Admin'), [user]);

    return (
        <>
        <Helmet>
            <title>{fromRoot ? 'Watch Shuffle' : 'Watch Shuffle - Buy'}</title>
            <meta name="description" content={fromRoot ? 'Watch Shuffle is a premium online watch auction house that is honest, transparent and hassle free. Buying and selling that is safe, secure and easy. No selling fees. Expert curation.' : 'View premium watches currently on auction or coming soon. Top brands and models available with low purchase fee.'} />
            <link rel="canonical" href={fromRoot ? '/' : '/buy'} />
        </Helmet>

        <div className={styles.root}>
            <div className={styles.contentContainer}>
                <div className={styles.imageContainer1}>
                    <img
                        className={styles.auctionBuyImage}
                        src={
                            isDesktop
                                ? AuctionBuyImage1
                                : AuctionBuyImage1Mobile
                        }
                        alt="Auction Buy"
                    />
                </div>

                {isDesktop ? (
                    <div className={styles.filterPanel}>
                        <div className={styles.statusFilterContainer}>
                            <StatusFilter
                                selected={statusFilter}
                                onChange={onStatusFilterChanged}
                                statusToCount={statusToCount}
                            />
                        </div>

                        <div className={styles.sortByContainer}>
                            <Typography className={styles.sortByLabel}>
                                Sort By:
                            </Typography>
                            <div className={styles.sortByDropdownContainer}>
                                <Select
                                    id="sortBySelect"
                                    classes={sortByClasses}
                                    variant="standard"
                                    value={sortBy}
                                    onChange={onSortByChange}
                                >
                                    <MenuItem value={"brand"}>Brand</MenuItem>
                                    <MenuItem value={"model"}>Model</MenuItem>
                                    <MenuItem value={"endingSoon"}>
                                        Ending Soon
                                    </MenuItem>
                                    <MenuItem value={"currentBid"}>
                                        Current Bid
                                    </MenuItem>
                                </Select>
                            </div>
                        </div>

                        <div className={styles.spacer} />

                        <div className={styles.searchContainer}>
                            <TextField
                                id="searchField"
                                placeholder="Search"
                                type="search"
                                variant="filled"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                            className={
                                                styles.searchIconContainer
                                            }
                                        >
                                            <SearchIcon
                                                className={styles.searchIcon}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    input: {
                                        "&::placeholder": {
                                            opacity: 1,
                                        },
                                    },
                                }}
                                value={searchText}
                                onChange={onSearchChange}
                            />
                        </div>
                    </div>
                ) : (
                    <div className={styles.filterPanelMobile}>
                        <div className={styles.statusFilterContainer}>
                            <StatusFilter
                                selected={statusFilter}
                                onChange={onStatusFilterChanged}
                                statusToCount={statusToCount}
                            />
                        </div>

                        <div className={styles.searchContainerMobile}>
                            <TextField
                                id="searchField"
                                placeholder="Search"
                                type="search"
                                variant="filled"
                                sx={{
                                    input: {
                                        "&::placeholder": {
                                            opacity: 1,
                                        },
                                    },
                                }}
                                value={searchText}
                                onChange={onSearchChange}
                            />
                        </div>
                    </div>
                )}

                {!displayedAuctionItems.length ? (
                    <div
                        className={
                            isDesktop
                                ? styles.noItemsContainer
                                : styles.noItemsContainerMobile
                        }
                    >
                        <Typography className={styles.noItemsText}>
                            No watches found for the selected criteria.
                        </Typography>
                    </div>
                ) : isDesktop ? (
                    <div className={styles.itemsContainer}>
                        {displayedAuctionItems.map((x) => (
                            <AuctionItem
                                key={x.id}
                                info={x}
                                onCountdownFinished={onCountdownFinished}
                                onFavouritesToggle={onFavouritesToggle}
                                show={!!user && !isTenantAdmin}
                            />
                        ))}
                    </div>
                ) : (
                    <div className={styles.itemsContainerMobile}>
                        <div className={styles.itemsContainerInner}>
                            {displayedAuctionItems.map((x) => (
                                <AuctionItemMobile
                                    key={x.id}
                                    info={x}
                                    onCountdownFinished={onCountdownFinished}
                                    onFavouritesToggle={onFavouritesToggle}
                                    show={!!user && !isTenantAdmin}
                                />
                            ))}
                        </div>
                    </div>
                )}

                <div className={styles.pagesContainer}>
                    <PageSelector maxPages={numPagesToDisplay} pageSelected={currentPage} numPagesToDisplay={numPagesToDisplay} onPageChanged={onPageChanged} />
                </div>
            </div>

            <Footer hideInfoLinks={true} />
        </div>
        </>
    );
};

export default AuctionBuy;
