import { daxClosedApi } from "@hmxlabs/dax-client";
import { ActiveUserSubscriptions } from "../models/ActiveUserSubscriptions";
import { nanoid } from "@reduxjs/toolkit";
import { BidsForNotSoldListings } from "../models/BidsForNotSoldListings";

export const getActiveUserSubscriptionsReport = async(): Promise<ActiveUserSubscriptions[]> => {
    const cancelToken = nanoid();
    const results = await daxClosedApi.reportsGetActiveUserSubscriptions<ActiveUserSubscriptions[]>({cancelToken});
    return results;
}

export const getBidsForNotSoldListingsReport = async(): Promise<BidsForNotSoldListings[]> => {
    const cancelToken = nanoid();
    const results = await daxClosedApi.reportsGetBidsForNotSoldListings<BidsForNotSoldListings[]>({cancelToken});
    return results;
}