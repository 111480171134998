import { useCallback, useContext, useState } from "react";
import styles from './TenantAdminReports.module.scss';
import { Button, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { getActiveUserSubscriptionsReport, getBidsForNotSoldListingsReport } from "../../../services/reports-service";
import { SnackContext } from "../../../providers/SnackProvider";
import { loggingService } from "../../../../utils/logging/logging";
import { ColDef } from 'ag-grid-community'; 
import { ReportDialog } from "./ReportDialog";


const { log } = loggingService("app:TenantAdminReports");

const activeUserSubscriptionsColDefs: ColDef[] = [
    { field: "firstName", headerName: "First Name" },
    { field: "lastName", headerName: "Last Name" },
    { field: "publicDisplayName", headerName: "Display Name" },
    { field: "userName", headerName: "User Name" },
    { field: "subscribeToNewsLetter", headerName: "Subscribed To NewsLetter" },
];

const bidsForNotSoldListingsColDefs: ColDef[] = [
    { field: "manufacturer", headerName: "Manufacturer" },
    { field: "model", headerName: "Model" },
    { field: "variant", headerName: "Variant" },
    { field: "status", headerName: "Status" },
    { field: "endsOn", headerName: "EndsOn" },
    { field: "userName", headerName: "User Name" },
    { field: "email", headerName: "Email" },
    { field: "amount", headerName: "Bid Amount" },
    { field: "bidTime", headerName: "Bid Time" },
];

interface ReportType {
    key: string,
    displayName: string,
    description: string
}

const AvailableReportTypes: ReportType[] = [
    {key: 'ActiveUserSubscriptions', displayName: 'Active User Subscriptions', description: 'Get the list of active users and their newsletter subscription status'},
    {key: 'BidsForNotSoldListings', displayName: 'Bids For Not Sold Listings', description: 'Get bids for listings that ended in the Not Sold state in the last 30 days'},
]

export const TenantAdminReports = () => {
    const showSnackbar = useContext(SnackContext);

    const [selectedReportType, setSelectedReportType] = useState<ReportType>(AvailableReportTypes[0]);
    const [showGrid, setShowGrid] = useState(false);
    const [reportTitle, setReportTitle] = useState('');
    const [rowData, setRowData] = useState<any[]>([]);

    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState<ColDef[]>([]);

    const onReportTypeChanged = useCallback((e: SelectChangeEvent<string>) => {
        const found = AvailableReportTypes.find(x => x.key === e.target.value);

        if (found) {
            setSelectedReportType(found);
        }
    }, []);

    const generateActiveUserSubscriptionsReport = useCallback(async () => {
        try {
            const results = await getActiveUserSubscriptionsReport();
            log("ActiveUserSubscriptions report", results);
            setReportTitle('Active User Subscriptions');
            setColDefs(activeUserSubscriptionsColDefs);
            setRowData(results);
            setShowGrid(true);
        } catch (err) {
            log("Error retrieving ActiveUserSubscriptions report", err);
            showSnackbar(
                "An error occurred loading the report",
                { alertSeverity: "error" },
            );
        }
    }, [showSnackbar]);

    const generateBidsForNotSoldListingsReport = useCallback(async () => {
        try {
            const results = await getBidsForNotSoldListingsReport();
            log("BidsForNotSoldListings report", results);
            setReportTitle('Bids For Not Sold Listings');
            setColDefs(bidsForNotSoldListingsColDefs);
            setRowData(results);
            setShowGrid(true);
        } catch (err) {
            log("Error retrieving BidsForNotSoldListings report", err);
            showSnackbar(
                "An error occurred loading the report",
                { alertSeverity: "error" },
            );
        }
    }, [showSnackbar]);

    const onGenerateReportClicked = useCallback(() => {
        switch (selectedReportType.key) {
            case 'ActiveUserSubscriptions': 
                generateActiveUserSubscriptionsReport();
                break;
            case 'BidsForNotSoldListings': 
                generateBidsForNotSoldListingsReport();
                break;
        }
    }, [generateActiveUserSubscriptionsReport, generateBidsForNotSoldListingsReport, selectedReportType.key]);

    const onCloseReportDialog = useCallback(() => {
        setShowGrid(false);
    }, []);

    return (
        <Stack direction='column' className={styles.root}>
            <Stack direction='row' className={styles.reportTypeContainer}>
                <Typography className={styles.label}>Report Type:</Typography>
                <Select
                    className={styles.reportTypeDropdown}
                    variant="outlined"
                    value={selectedReportType.key}
                    onChange={onReportTypeChanged}
                >
                    {AvailableReportTypes.map(report => 
                        <MenuItem key={report.key} value={report.key}>{report.displayName}</MenuItem>
                    )}
                </Select>
                <Button className={styles.generateReportButton} variant="contained" onClick={onGenerateReportClicked}>
                    <Typography className={styles.generateReportText}>Generate</Typography>
                </Button>
            </Stack>

            <Stack direction='row' className={styles.reportDescriptionContainer}>
                <Typography className={styles.label}>Description:</Typography>
                <Typography className={styles.description}>{selectedReportType.description}</Typography>
            </Stack>

            <ReportDialog
                isOpen={showGrid}
                onClose={onCloseReportDialog}
                data={rowData}
                colDefs={colDefs}
                title={reportTitle}
            />
        </Stack>
    );
}
