import styles from './App.module.scss';
import Header from "./app/components/header/Header";
import { Routes, Route, useLocation} from "react-router-dom"
import AuctionBuy from "./app/components/pages/auction/buy/AuctionBuy";
import AuctionBuyDetails from "./app/components/pages/auction/buyDetails/AuctionBuyDetails";
import ComingSoon from "./app/components/pages/comingSoon/ComingSoon";
import FAQ from "./app/components/pages/faq/FAQ";
import TermsConditions from "./app/components/pages/termsAndConditions/Terms";
import PrivacyCookies from "./app/components/pages/privacyAndCookies/PrivacyCookies";
import { useMediaQuery } from "react-responsive";
import ComingSoonMobile from "./app/components/pages/comingSoon/ComingSoonMobile";
import Shuffle from "./app/components/pages/shuffle/Shuffle";
import MemberDetails from "./app/components/pages/memberDetails/MemberDetails";
import { MobileScreenWidth } from "./constants";
import AuctionSell from "./app/components/pages/auction/sell/AuctionSell";
import ContactInfo from "./app/components/pages/contact/ContactInfo";
import { Stack } from "@mui/material";
import { useEffect, useRef } from 'react';
import StripeComplete from './app/components/pages/memberDetails/StripeComplete';

function App() {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });

    const { pathname, hash, key } = useLocation();
    const bodyRef = useRef<HTMLDivElement | null>(null); 

    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            if (bodyRef.current) {
                bodyRef.current.scroll({
                    top: 0,
                    behavior: "smooth"
                });
            } else {
                window.scrollTo(0, 0);
            }
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
    }, [pathname, hash, key]);    

    return (
        <Stack direction='column' className={styles.root}>
            <div className={styles.headerContainer}>
                <Header />
            </div>
            <div className={styles.bodyContainer} ref={bodyRef}>
                <Routes>
                    <Route path="/" element={<AuctionBuy fromRoot={true} />} />
                    <Route path="/info" element={isDesktop ? <ComingSoon /> : <ComingSoonMobile />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/buy" element={<AuctionBuy fromRoot={false} />} />
                    <Route path="/sell" element={<AuctionSell />} />
                    <Route path="/shuffle" element={<Shuffle />} />
                    <Route path="/details" element={<AuctionBuyDetails bodyRef={bodyRef} />} />
                    <Route path="/contact" element={<ContactInfo />} />
                    <Route path="/member" element={<MemberDetails />} />
                    <Route path="/stripe" element={<StripeComplete />} />
                    <Route path="/terms&conditions" element={<TermsConditions />} />
                    <Route path="/privacy" element={<PrivacyCookies info="privacy" />} />
                    <Route path="/cookies" element={<PrivacyCookies info="cookies" />} />
                    {/* <Route path="/simulator" element={<Simulator />} /> */}
                </Routes>
            </div>
        </Stack>
    );
}

export default App;
