import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import styles from './ReportDialog.module.scss';
import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModule, ColDef, GridApi, GridReadyEvent, ModuleRegistry } from 'ag-grid-community'; 
import { useCallback, useState } from "react";

ModuleRegistry.registerModules([AllCommunityModule]);

const defaultColDef = {
    flex: 1,
};

interface ReportDialogProps {
    title: string;
    colDefs: ColDef[];
    data: any[];
    isOpen: boolean;    
    onClose: () => void;
}

export const ReportDialog = ({title, colDefs, data, isOpen, onClose}: ReportDialogProps) => {
    const [gridApi, setGridApi] = useState<GridApi>();

    const onGridReady = useCallback((event: GridReadyEvent) => {
        setGridApi(event.api);
    }, [])

    const onExportToCsvClicked = useCallback(() => {
        if (gridApi) {
            gridApi.exportDataAsCsv();
        }
    }, [gridApi]);

    return (
        <Dialog open={isOpen} onClose={onClose} className={styles.root} sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "1000px",  // Set your width here
              },
            },
        }}>
            <DialogTitle className={styles.dialogTitleContainer}>
                <Typography className={styles.titleText}>{title}</Typography>
                
                <IconButton aria-label="Close" onClick={onClose} sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8
                }}>
                    <CloseIcon className={styles.closeIcon} />
                </IconButton>
            </DialogTitle>
            <DialogContent className={styles.contentContainer}>
                <Stack direction='column' className={styles.gridContainer}>
                    <AgGridReact
                        onGridReady={onGridReady}
                        rowData={data}
                        columnDefs={colDefs}
                        defaultColDef={defaultColDef}
                    />
                    <Stack direction='row' className={styles.buttonsContainer}>
                        <Button className={styles.exportToCsvButton} variant="contained" onClick={onExportToCsvClicked}>
                            <Typography className={styles.exportToCsvText}>Export to CSV</Typography>
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}