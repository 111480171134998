import styles from './TermsConditions.module.scss';
import ShuffleImage1 from '../../../assets/shuffleImage1.jpg';
import ShuffleImage1Mobile from '../../../assets/shuffleImage1Mobile.jpg';
import Footer from '../../footer/Footer';
import Typography from '@mui/material/Typography';
import TermsItem from './TermsItem';
import { termsConditons } from './TermsConditionsData';
import { useMediaQuery } from 'react-responsive';
import { MobileScreenWidth } from '../../../../constants';
import { Helmet } from 'react-helmet-async';

const TermsConditions = () => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });
        

    return (
        <>
        <Helmet>
            <title>Watch Shuffle - Terms And Conditions</title>
            <meta name="description" content="Watch Shuffle terms and conditions for buyers and sellers." />
            <link rel="canonical" href="/terms&conditions" />
        </Helmet>

        <div className={styles.root}>
            <div className={styles.imageContainer1}>
                <img className={styles.termsImage} src={isDesktop ? ShuffleImage1 : ShuffleImage1Mobile} alt="TERMS" />
            </div>

            <div className={styles.titleContainer}>
                <Typography className={styles.titleText}>Terms and Conditions for Watch Shuffle Limited. January 2025</Typography>
            </div>

            {isDesktop ? (
                <div className={styles.termsContainer}>
                    {termsConditons.map((item, index) => 
                        <TermsItem key={item.id} item={item} index={index} />
                    )}
                </div>
            ) : (
                <div className={styles.termsContainerMobile}>
                    <div className={styles.termsItems}>
                        {termsConditons.map((item, index) => 
                            <TermsItem key={item.id} item={item} index={index} />
                        )}
                    </div>
                </div>
            )}

            <Footer hideInfoLinks={true} />
        </div>
        </>
    );
}

export default TermsConditions;