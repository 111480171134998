import styles from "./PrivacyCookies.module.scss";
import ShuffleImage1 from "../../../assets/shuffleImage1.jpg";
import ShuffleImage1Mobile from "../../../assets/shuffleImage1Mobile.jpg";
import Footer from "../../footer/Footer";
import Typography from "@mui/material/Typography";
import PrivacyAndCookiesItem from "./Item";
import { privacyData, cookiesData } from "./PrivacyCookiesData";
import { useMediaQuery } from "react-responsive";
import { MobileScreenWidth } from "../../../../constants";
import { Helmet } from "react-helmet-async";

const PrivacyCookies = ({ info }: { info: string }) => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });

    const dataToDisplay = info === "privacy" ? privacyData : cookiesData;

    return (
        <>
        <Helmet>
            <title>Watch Shuffle - {info === 'privacy' ? 'Privacy' : 'Cookies'}</title>
            <meta name="description" content={info === 'privacy' ? 'Watch Shuffle privacy policy regarding information collection, IP addresses, data storage, information usage, watch loss register, communications monitoring, your rights, changes to policy and contact details.' : 'Watch Shuffle policy regarding cookie privacy, third-party cookies and cookie settings.'} />
            <link rel="canonical" href={info === 'privacy' ? '/privacy' : '/cookies'} />
        </Helmet>

        <div className={styles.root}>
            <div className={styles.imageContainer1}>
                <img
                    className={styles.termsImage}
                    src={isDesktop ? ShuffleImage1 : ShuffleImage1Mobile}
                    alt="TERMS"
                />
            </div>

            <div className={styles.titleContainer}>
                {info === "privacy" ? (
                    <Typography className={styles.titleText}>
                        At WatchShuffle, protecting your privacy is of utmost
                        importance to us. This policy explains how we collect
                        and process your personal data and the steps we take to
                        ensure its security and confidentiality. Please read
                        this carefully to understand how we handle your
                        information.
                    </Typography>
                ) : (
                    <></>
                )}
            </div>

            {isDesktop ? (
                <div className={styles.termsContainer}>
                    {dataToDisplay.map((item, index) => (
                        <PrivacyAndCookiesItem
                            key={item.id}
                            item={item}
                            index={index}
                        />
                    ))}
                </div>
            ) : (
                <div className={styles.termsContainerMobile}>
                    <div className={styles.termsItems}>
                        {dataToDisplay.map((item, index) => (
                            <PrivacyAndCookiesItem
                                key={item.id}
                                item={item}
                                index={index}
                            />
                        ))}
                    </div>
                </div>
            )}

            <Footer hideInfoLinks={true} />
        </div>
        </>
    );
};

export default PrivacyCookies;
