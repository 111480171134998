import styles from './Shuffle.module.scss';
import ShuffleImage1 from '../../../assets/shuffleImage1.jpg';
import ShuffleImage1Mobile from '../../../assets/shuffleImage1Mobile.jpg';
import Footer from '../../footer/Footer';
import Typography from '@mui/material/Typography';
import {ReactComponent as AppraisalIcon} from '../../../assets/appraisal.svg';
import {ReactComponent as MatchIcon} from '../../../assets/match.svg';
import {ReactComponent as EnjoyIcon} from '../../../assets/enjoy.svg';
import { useMediaQuery } from "react-responsive";
import { MobileScreenWidth } from '../../../../constants';
import { Helmet } from 'react-helmet-async';

const Shuffle = () => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });

    return (
        <>
        <Helmet>
            <title>Watch Shuffle - Shuffle</title>
            <meta name="description" content="Coming Soon. Free watch valuation, list your watch for exchange, find a watch listed by another user and, if both parties agree, make the exchange." />
            <link rel="canonical" href="/shuffle" />
        </Helmet>

        <div className={isDesktop ? styles.root : styles.rootMobile}>
            <div className={styles.imageContainer1}>
                <img className={styles.auctionSellImage} src={isDesktop ? ShuffleImage1 : ShuffleImage1Mobile} alt="Auction Sell" />
            </div>

            <div className={styles.titleContainer}>
                <Typography className={styles.titleText}>SHUFFLE YOUR WAY</Typography>
                <Typography className={styles.comingSoonText}>(COMING SOON)</Typography>
            </div>

            <div className={styles.contentContainer}>
                <div style={{gridColumn: 1}} className={styles.partContainer1}>
                    <AppraisalIcon className={styles.backgroundIcon} />
                    <div className={styles.partContainer2}>
                        <Typography className={styles.headerText}>APPRAISAL</Typography>
                        <Typography className={styles.bodyText}>Send us a picture of your watch along with the details and we will give you an estimate of the value.</Typography>
                    </div>
                </div>

                <div style={{gridColumn: 2}} className={styles.partContainer1}>
                    <MatchIcon className={styles.backgroundIcon} />
                    <div className={styles.partContainer2}>
                        <Typography className={styles.headerText}>MATCH</Typography>
                        <Typography className={styles.bodyText}>Flick through other Watch Shuffle users watches and request a Shuffle when you see something you like.</Typography>
                    </div>
                </div>

                <div style={{gridColumn: 3}} className={styles.partContainer1}>
                    <EnjoyIcon className={styles.backgroundIcon} />
                    <div className={styles.partContainer2}>
                        <Typography className={styles.headerText}>ENJOY!</Typography>
                        <Typography className={styles.bodyText}>If the other Watch Shuffle user likes your watch, boom, it’s a match! All you need to do now is wait for your new watch to arrive.</Typography>
                    </div>
                </div>
            </div>

            <Footer hideInfoLinks={true} />
        </div>
        </>
    );
}

export default Shuffle;